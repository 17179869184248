import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { PROFILE_PATHNAME } from '@glass/common/modules/pages/paths';

import AuthSection from '@glass/shared/components/Section/AuthSection/AuthSection';
import { DISABLE_LOADING, SSR_LOADING } from '@glass/shared/components/Section/loadingMethods';
import { selectAuthProps } from '@glass/shared/modules/authForm';
import AUTH_DESCRIPTIONS from '@glass/shared/modules/authForm/authDescriptions';
import AUTH_TITLES from '@glass/shared/modules/authForm/authTitles';
import createMemoPropKeys from '@glass/shared/modules/react/createMemoPropKeys';

const footerProps = {
  resourceSectionLoadingMethod: DISABLE_LOADING,
  supportSectionLoadingMethod: SSR_LOADING,
};

const headerProps = {
  addFixedHeaderSpacing: true,
};

const inDialogChange = createMemoPropKeys('inDialog');

function AuthPage({
  layout: Layout,
  initialActionType,
  initialView,
  initialAvailableActions,
  initialRequirePassword,
  initialNext: defaultInitialNext,
  canonicalPath,
}) {
  const { inDialog } = useSelector(selectAuthProps, inDialogChange);
  return (
    <Layout
      canonicalPath={canonicalPath}
      description={AUTH_DESCRIPTIONS[initialActionType]}
      footerProps={footerProps}
      headerProps={headerProps}
      pageTitle={AUTH_TITLES[initialActionType]}
    >
      <AuthSection
        defaultInitialNext={defaultInitialNext}
        initialActionType={initialActionType}
        initialAvailableActions={initialAvailableActions}
        initialRequirePassword={initialRequirePassword}
        initialView={initialView}
        isVisible={!inDialog}
      />
    </Layout>
  );
}

AuthPage.propTypes = {
  canonicalPath: PropTypes.string,
  initialActionType: PropTypes.string.isRequired,
  initialAvailableActions: PropTypes.arrayOf(PropTypes.string),
  initialNext: PropTypes.string,
  initialRequirePassword: PropTypes.bool,
  initialView: PropTypes.string.isRequired,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

AuthPage.defaultProps = {
  initialNext: PROFILE_PATHNAME,
};

export default React.memo(AuthPage);
