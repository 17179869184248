import {
  CREATE_ACCOUNT_ACTION,
  RESET_PASSWORD_ACTION,
  ROCKET_LINK_ACTION,
  SIGNIN_ACTION,
} from '@glass/shared/modules/auth/authActionTypes';

const AUTH_DESCRIPTIONS = {
  [CREATE_ACCOUNT_ACTION]: 'Create a Rocket Resume account and build your perfect online resume',
  [SIGNIN_ACTION]: 'Sign in to your Rocket Resume account and create your resume in minutes',
  [RESET_PASSWORD_ACTION]: 'Reset your Rocket Resume password to get access to your account',
  [ROCKET_LINK_ACTION]:
    'Enter your email to get a Rocket Link sent to your inbox to reset your account',
};

export default AUTH_DESCRIPTIONS;
