import React from 'react';

import { SIGNIN_PATHNAME } from '@glass/common/modules/pages/paths';

import AuthPage from '@glass/shared/components/Auth/AuthPage';
import ConsumerLayoutPage from '@glass/shared/components/Layout/ConsumerLayoutPage';
import { SIGNIN_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import { EMAIL_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

function SignInPage() {
  return (
    <AuthPage
      initialRequirePassword
      canonicalPath={SIGNIN_PATHNAME}
      initialActionType={SIGNIN_ACTION}
      initialView={EMAIL_AUTH_VIEW}
      layout={ConsumerLayoutPage}
    />
  );
}

export default SignInPage;
