import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import {
  MESSAGE_QUERY,
  NEXT_QUERY,
  TEMPLATE_QUERY,
  TEMPLATE_VIEW_QUERY,
} from '@glass/common/modules/pages/queryParams';
import Container from '@glass/web/components/Layout/Container';
import Fade from '@glass/web/components/base/Fade';
import Paper from '@glass/web/components/base/Paper';
import { AUTH_SECTION_ID } from '@glass/web/modules/content/pages/contentIds';
import { XS } from '@glass/web/modules/theme/breakpointKeys';

import AuthForm from '@glass/shared/components/Auth/AuthForm';
import Section from '@glass/shared/components/Section/Section';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const ctaQueryParams = { [TEMPLATE_VIEW_QUERY]: TEMPLATE_QUERY };
const AuthSection = React.forwardRef(
  (
    {
      title,
      subtitle,
      isAlternatingColor,
      type,
      initialActionType,
      initialAvailableActions,
      initialRequirePassword,
      initialView,
      defaultInitialNext,
      isVisible,
    },
    ref,
  ) => {
    const {
      query: {
        [NEXT_QUERY]: initialNext = defaultInitialNext,
        [MESSAGE_QUERY]: initialMessage,
      } = {},
    } = useRouter();
    return (
      <Section
        ref={ref}
        disableCta
        ctaQueryParams={ctaQueryParams}
        isAlternatingColor={isAlternatingColor}
        subtitle={subtitle}
        title={title}
        type={type}
      >
        <Container isNoXPadding isNoYPadding maxWidth={XS}>
          <Fade appear={false} in={isVisible}>
            <Paper>
              <AuthForm
                initialActionType={initialActionType}
                initialAvailableActions={initialAvailableActions}
                initialMessage={initialMessage}
                initialNext={initialNext}
                initialRequirePassword={initialRequirePassword}
                initialView={initialView}
              />
            </Paper>
          </Fade>
        </Container>
      </Section>
    );
  },
);

AuthSection.propTypes = {
  defaultInitialNext: PropTypes.string,
  initialActionType: PropTypes.string.isRequired,
  initialAvailableActions: PropTypes.arrayOf(PropTypes.string),
  initialRequirePassword: PropTypes.bool,
  initialView: PropTypes.string,
  isAlternatingColor: PropTypes.bool,
  isVisible: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

AuthSection.defaultProps = {
  isVisible: true,
};

export default React.memo(withTrackingContext(AuthSection, AUTH_SECTION_ID));
